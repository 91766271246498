import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { CommunityModel } from '@/models/community-model'
import { PostModel, TagModel } from '@/models/post-model'
import { apiService } from '@/services/api-services'
import { PostStore } from '@/stores/post-store'
import { action, computed, observable, toJS, when } from 'mobx'
import { asyncAction } from 'mobx-utils'

export class RecruitmentApplyController {
  @observable openRctApplyDialog = false
  @observable openConfirmDiscardPostDialog = false
  @observable attachFile?: File = undefined
  @observable postStore?: PostStore = undefined
  @observable emailAddress = ''
  @observable phoneNumber = ''
  @observable introduction = ''
  @observable isApplied = false
  @observable invalidCv = false

  @action clearData() {
    this.emailAddress = ''
    this.phoneNumber = ''
    this.introduction = ''
    this.attachFile = undefined
    this.isApplied = false
    this.invalidCv = false
  }

  constructor() {
    //
  }

  @action changeAttachFile(file?: File) {
    this.attachFile = file
    this.invalidCv = false
  }

  @asyncAction *uploadJdFile() {
    if (!this.attachFile) return null
    const res = yield apiService.posts.upload(this.attachFile)
    return res?.length ? res[0].id || null : null
  }

  @action.bound show(value: boolean, postStore: PostStore | undefined = undefined) {
    this.postStore = postStore
    this.openRctApplyDialog = value
    this.clearData()
  }

  @asyncAction *submitApply() {
    try {
      if (!this.attachFile) {
        this.invalidCv = true
        return
      }
      if (!this.postStore?.post.id) return
      const cvFile = yield this.uploadJdFile()
      const payload = {
        emailAddress: this.emailAddress,
        phoneNumber: this.phoneNumber,
        introduction: this.introduction,
        cvFile,
        post: this.postStore.post.id,
      }
      yield apiService.recruitmentApplyHandler.submitApply(payload)
      this.isApplied = true
      this.openRctApplyDialog = false
    } catch (e) {
      snackController.commonError(e)
    }
  }
}

export const recruitmentApplyController = new RecruitmentApplyController()
