




















































import { Observer } from 'mobx-vue'
import { Component, Prop, Provide, Vue, Watch } from 'vue-property-decorator'
import { PostDetailViewmodel } from '@/modules/recruitment/daos/viewmodels/post-detail-viewmodel'
import { when } from 'mobx'
import { recruitmentStore } from '@/stores/recruitment-store'

@Observer
@Component({
  components: {
    'rct-post-header': () => import('@/modules/recruitment/posts/components/rct-post-header.vue'),
    'rct-report-reason': () => import('@/modules/recruitment/posts/components/rct-report-reason.vue'),
    'rct-post-footer': () => import('@/modules/recruitment/posts/components/rct-post-footer.vue'),
    'rule-board': () => import('@/modules/community-organize/components/highlight-boards/rule-board.vue'),
    'faq-board': () => import('@/modules/community-organize/components/highlight-boards/faq-board.vue'),
    'rct-dao-manage-board': () => import('@/modules/recruitment/daos/components/rct-dao-manage-board.vue'),
    'recruitment-apply-dialog': () => import('@/modules/recruitment/dialogs/recruitment-apply-dialog.vue'),
  },
})
export default class extends Vue {
  @Provide() vm = new PostDetailViewmodel()
  focusing = ''
  recruitmentStore = recruitmentStore
  @Watch('$route.params.id', { immediate: true })
  postIdChanged(id) {
    if (id) this.vm.fetchData(id)
  }

  @Watch('$route.query.comment', { immediate: true })
  async commentMention(value) {
    if (value) {
      await when(() => !!this.vm.postStore)
      this.vm.postStore?.changeEnableComment()
      this.focusing = value
    }
  }
}
