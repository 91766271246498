import { appProvider } from '@/app-provider'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { CommunityModel } from '@/models/community-model'
import { ProfileModel } from '@/models/profile-model'
import { postController } from '@/modules/common/dialogs/post/post-controller'
import { apiService } from '@/services/api-services'
import { PostStore } from '@/stores/post-store'
import { recruitmentStore } from '@/stores/recruitment-store'
import { walletStore } from '@/stores/wallet-store'
import { map } from 'lodash'
import { computed, IReactionDisposer, observable, reaction, when } from 'mobx'
import { asyncAction } from 'mobx-utils'
import { recruitmentApplyController } from '../../dialogs/recruitment-apply-controller'
import { recruitmentPostController } from '../../dialogs/recruitment-post-controller'

export class PostDetailViewmodel {
  @observable loadingPost = false
  @observable loadingFollow = false
  @observable postStore?: PostStore = undefined
  @observable isNoPost = false
  @observable communityProfile?: CommunityModel = undefined
  @observable contributors?: ProfileModel[] = []
  @observable communityId?: ProfileModel[] = []
  @observable isApplied = false
  @observable getAppliedRctLoaded = false

  private _disposers: IReactionDisposer[]

  constructor() {
    this.communityId = process.env.VUE_APP_RECRUITMENT_ID as any

    this._disposers = [
      reaction(
        () => recruitmentPostController.completedUpdateType,
        (type) => {
          if (type) {
            this.fetchData(this.postStore?.post?.id)
            recruitmentPostController.changeCompletedUpdateType(undefined)
          }
        }
      ),
      reaction(
        () => postController.completeUpdateType,
        (type) => {
          if (!type) return
          if (type === 'delete' || type === 'block') appProvider.router.push('/community/my-page')
          else this.fetchData(this.postStore?.post?.id)
          postController.changeCompleteUpdateType(undefined)
        }
      ),
      reaction(
        () => walletStore.userProfile,
        (profile) => {
          if (profile) {
            this.isNoPost = profile.totalPosts === 0
          }
        },
        {
          fireImmediately: true,
        }
      ),
      reaction(
        () => recruitmentApplyController.isApplied,
        (isApplied) => {
          this.isApplied = isApplied
        },
        {
          fireImmediately: true,
        }
      ),
    ]

    this.loadData()
  }

  @asyncAction *loadData() {
    recruitmentStore.loadRecruitment()
    yield when(() => recruitmentStore.recruitmentLoaded)
    this.communityProfile = recruitmentStore.recruitmentDao
    this.contributors = recruitmentStore.contributors
  }

  @asyncAction *getAppliedRct() {
    try {
      const postId = this.postStore?.post?.id
      const profileId = walletStore.userProfile?._id
      if (!postId || !profileId) return
      const res = yield apiService.recruitmentApplyHandler.findOneApply({ postId, profileId })
      this.isApplied = !!res?.length
    } catch (e) {
      snackController.commonError(e)
    } finally {
      this.getAppliedRctLoaded = true
    }
  }

  destroy() {
    this._disposers.forEach((d) => d())
  }

  @asyncAction *fetchData(_id?: string) {
    try {
      if (!_id) return
      this.loadingPost = true
      const posts = yield apiService.posts.fetchPosts({ id: _id, type: 'recruitment' }, { _limit: 1 })
      this.postStore = posts?.length ? new PostStore(posts[0]) : undefined
      this.getAppliedRct()
    } catch (e) {
      console.log('err', e)
    } finally {
      this.loadingPost = false
    }
  }

  @computed get avatarUrl() {
    return this.communityProfile?.avatar?.url
  }

  @computed get rules() {
    return this.communityProfile?.rule?.list || []
  }
  @computed get faqs() {
    return this.communityProfile?.faq?.list || []
  }

  @computed get owner() {
    return this.communityProfile?.profile
  }

  @computed get isOwnerDao() {
    return this.owner && this.owner._id === walletStore.userProfile?._id
  }

  @computed get isContributorDao() {
    return map(this.contributors, 'id').includes(walletStore.userProfile?._id)
  }
  @computed get isAdmin() {
    return this.isOwnerDao || this.isContributorDao
  }

  @computed get showApplyBtn() {
    return !this.postStore?.isPostOwner && !this.isAdmin && !this.postStore?.isRctClosed
  }
}
