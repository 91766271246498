import { render, staticRenderFns } from "./post-detail-page.vue?vue&type=template&id=f24c3432&scoped=true&"
import script from "./post-detail-page.vue?vue&type=script&lang=ts&"
export * from "./post-detail-page.vue?vue&type=script&lang=ts&"
import style0 from "./post-detail-page.vue?vue&type=style&index=0&id=f24c3432&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f24c3432",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VBtn,VContainer,VDivider,VIcon,VSkeletonLoader})
